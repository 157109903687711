.fragrance{
    width: 35%;
    display: inline-block;
    padding: 20px;
    margin: auto;
}

.fragrance .image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.innerModal {
    font-family: sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-height: 70%;
    padding: 15px;
    transform: translate(-50%, -50%);
    border: none;
    overflow: auto;
}
.innerModal.ambientales {
    background-color: rgb(255, 241, 214);
    color: rgb(111 11 105);
}
.innerModal.limpieza {
    background-color: rgb(255, 241, 214);
    color: rgb(24 88 135);
}
.innerModal.perfumeria {
    background-color: rgb(255, 241, 214);
}
.innerModal.velas {
    background-color: rgb(255, 241, 214);
    color: rgb(170 79 16);
}

.innerModal h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.innerModal .fragance-list {
    display: grid;
    list-style-type: none;
    grid-template-columns: 1fr 1fr 1fr;
}

.innerModal .fragance-list li {
    margin-bottom: 10px;
}
.innerModal .fragance-list li .exclusive {
    color: white;
    background-color: lightcoral;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    font-weight:600;
}

@media (max-width: 1024px) {
    .fragrance{
        width: 100%;
        padding: 0px;
    }

    .fragrance .image img {
        height: 200px;
    }

    .innerModal .fragance-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .innerModal .fragance-list {
        grid-template-columns: 1fr;
    }
}