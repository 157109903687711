.header{
    background-color: transparent;
    width: 100%;
    position: absolute;
    z-index: 100;
}

.header .logo {
    padding: 20px 0;
    text-align: center;
}

.header .logo img{
    height: 120px;
}

@media (max-width: 1024px) {
    .header{
        height: 120px;
    }
    .header .logo img{
        height: 80px;
    }
}