.contact-form {
    padding: 20px;
    background-color: #fbf7f7;
}
.contact-form input {
    display: inline-block;
    padding: 12px 20px;
    margin: 10px;

}
.contact-form textarea{
    display: block;
    padding: 15px 30px;
    margin: 0 10px 10px 10px;
    width: 585px;
}

.contact-form input,textarea{
    box-sizing: border-box;
    border: 1px solid #ced4da;
    font-family: sans-serif;
}

.contact-form button{
    border: 0;
    padding: 10px 24px;
    margin-left: 10px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
    background-color: lightcoral;
    cursor: pointer;
}

.contact-form .form-banner {
    padding: 10px;
    color: white;
    margin-top: 20px;
    border-radius: 5px;
}
.contact-form .form-banner.success {
    background-color: green;
}
.contact-form .form-banner.error {
    background-color: red;
}

.contact-form button:hover{
    background-color: coral;
}

@media (max-width: 1024px) {
    .contact-form input {
        display: block;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
    
    }
    .contact-form textarea{
        display: block;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
    }
    .contact-form button{
        width: 100%;
        margin: 0;
    }
}