.wa-widget-send-button {
    margin: 0 0 50px 0 !important;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed !important;
    z-index: 100 !important;
    bottom: 0 !important;
    text-align: center !important;
    height: 50px;
    min-width: 40px;
    border-radius: 25px;
    visibility: visible;
    transition: none !important;
    background-color: #4dc247;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
    right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wa-widget-send-button .wa-messenger-svg-whatsapp {
    fill: white;
    width: 41px;
    height: 50px;
    stroke: none;
}

@media (max-width: 1024px) {
    .wa-widget-send-button {
        right: 20px;
    }
}