.cover {
    background-color: #fbf7f7;
    padding: 20px 15px 20px 15px;
}

.cover .container{
    display: block;
    width: 100%;
}

.cover .container .image {
    width: 100%;
    height: 100%;
    display: block;
}

.cover .container .image .carrousel-container {
    height: 500px;
    background-size: cover;
}

.cover .container .image .carrousel-container.c1{
    background-image: url('/public/carousel1.jpg');
}
.cover .container .image .carrousel-container.c2{
    background-image: url('/public/carousel2.jpg');
}
.cover .container .image .carrousel-container.c3{
    background-image: url('/public/carousel3.jpg');
}
.cover .container .image .carrousel-container.c4{
    background-image: url('/public/carousel4.jpg');
}

@media (min-width: 1024px) {
    .cover .container .image .carrousel-container.c1{
        background-image: url('/public/carousel1_web.jpg');
    }
    .cover .container .image .carrousel-container.c2{
        background-image: url('/public/carousel2_web.jpg');
    }
    .cover .container .image .carrousel-container.c3{
        background-image: url('/public/carousel3_web.jpg');
    }
    .cover .container .image .carrousel-container.c4{
        background-image: url('/public/carousel4_web.jpg');
    }
}

.cover .container .text {
    padding: 0 15px;
    width: 70%;
    word-break: break-word;
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 1024px) {
    .cover .container .image {
        width: 100%;
        max-height: 200px;
    }

    .cover .container .image img{
        height: 200px;
        object-fit: contain;
        width: 100%;
    }

    .cover .container .text {
        width: 100%;
    }

    .cover .container .image .carrousel-container {
        height: 200px;
    }

}
