.footer{
    background-color: #fbf7f7;
    margin-top: 50px;
    padding: 50px 50px;
    display: block;
}

.footer .footerInfo {
    width: 50%;
    display: inline-block;
}

.footer .footerLinks {
    width: 50%;
    display: inline-block;
    text-align: right;
}

@media (max-width: 1024px) {
    .footer .footerInfo {
        display: block;
        width: 100%;
    }

    .footer .footerLinks {
        display: block;
        width: 100%;
        text-align: left;
    }
}